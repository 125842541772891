import './styles.css';

const Component = () => {
    return (
        <>
        <h1>Shop</h1>
        </>
    );
};

const data = {
    title: {
        ES: "Tienda",
        EN: "Shop"
    },
    href: 'shop',
    body: <Component/>,
}

export default data;