import {  	useContext,
          	useRef }      		from 'react';
import {  	FaHome, 
          	FaStar,
//          FaMusic, 
          	FaMicrophone,
//          FaShoppingCart,
          	FaBlog,
//          FaAtlas, 
          	FaExchangeAlt,
          	FaPlayCircle, 
			FaHandHoldingHeart,
          	FaInfo }      		from "react-icons/fa";
import { 	Link } 				from 'react-router-dom';
import { 	Button,
			Dropdown,
        	Stack }       		from 'react-bootstrap';
import {	Tooltip }      		from 'react-tooltip';

import 		LanguageContext		from 'context/language/Context';
import      * as dictionary     from './lang/index.js';

import './styles.css';


let lang = 'ES';
let text = dictionary[lang];

const BarLeft = () => {
	const { language } = useContext(LanguageContext);
	const ref = useRef(null);

	lang = language;
    text = dictionary[lang];

	return (
    <nav className="stack-container">
		<Stack gap={2} className="col-md-5 mx-auto stack-left" style={{justifyContent: "space-between"}}>
			<div className="stack-section text-center">
				<Link 
					className="nav-link"
					to={`/${language}/home`}
					aria-label={text['al-home']}
				>
					<Button 
						variant="secondary"
						aria-label={text['tt-home']}
						data-tooltip-id="tt-home"
						data-tooltip-content={text['tt-home']}
						data-tooltip-place="right"
					>
                    	<FaHome/>
                  	</Button>
                </Link>
                <Tooltip id="tt-home" />

                <Link 
					className="nav-link"
					to={`/${language}/artists`}
					aria-label={text['al-artist']}
				>
					<Button
						variant="secondary"
						aria-label={text['tt-artist']}
						data-tooltip-id="tt-artist"
						data-tooltip-content={text['tt-artist']}
						data-tooltip-place="right"
					>
                    	<FaMicrophone/>
                  	</Button>
                </Link>
                <Tooltip id="tt-artist" />

                <Link 
					className="nav-link"
					to={`/${language}/posts`}
					aria-label={text['al-blog']}
				>
					<Button 
						variant="secondary"
						aria-label={text['tt-blog']}
						data-tooltip-id="tt-blog"
						data-tooltip-content={text['tt-blog']}
						data-tooltip-place="right"
					>
                    	<FaBlog/>
                  	</Button>
                </Link>
                <Tooltip id="tt-blog" />

                <Link
					className="nav-link"
					to={`/${language}/playlists`}
					aria-label={text['al-playlist']}
				>
					<Button 
						variant="secondary"
						aria-label={text['tt-playlist']}
						data-tooltip-id="tt-special"
						data-tooltip-content={text['tt-playlist']}
						data-tooltip-place="right"
					>
						<FaPlayCircle/>
					</Button>
                </Link>
                <Tooltip id="tt-special" />
            </div>
            <div className="stack-section text-center" ref={ref}>
              	<Link 
					className="nav-link"
					to={`/${language}/artists/star`}
					aria-label={text['al-star']}
				>
					<Button 
						className="btn-star"
						variant="primary"
						aria-label={text['tt-star']}
						data-tooltip-id="tt-star"
						data-tooltip-content={text['tt-star']}
						data-tooltip-place="right"
					>
                  		<FaStar/>
                	</Button>
              	</Link>
              
              	<Tooltip id="tt-star" />
            </div>
            <div className="stack-section text-center">
				<Link 
					className="nav-link"
					to={`/${language}/about`}
					aria-label={text['al-about']}
				>
					<Button
						variant="secondary"
						aria-label={text['tt-about']}
						data-tooltip-id="tt-info"
						data-tooltip-content={text['tt-about']}
						data-tooltip-place="right"
					>
						<FaInfo/>
					</Button>
				</Link>
              	<Tooltip id="tt-info" />

				<Link 
					className="nav-link"
					to={`/${language}/mode`}
					aria-label={text['al-mode']}
				>
					<Button 
						variant="secondary"
						aria-label={text['tt-conf']}
						data-tooltip-id="tt-mode"
						data-tooltip-content={text['tt-conf']}
						data-tooltip-place="right"
					>
						<FaExchangeAlt/>
					</Button>
					<Tooltip id="tt-mode" />
				</Link>

				<Dropdown className="container-donations" drop="end">
					<Dropdown.Toggle 
						variant="success"
						aria-label={text['al-donations']}
						data-tooltip-id="tt-donations"
						data-tooltip-content={text['tt-donations']}
						data-tooltip-place="right"
					>
						<FaHandHoldingHeart/>
					</Dropdown.Toggle>
					<Dropdown.Menu className="dropdown-r0">
						<Dropdown.Item className="a-donation" href='https://cafecito.app/maurogrizia' rel='noopener' target='_blank'>
							<img className="img-donation" srcSet='https://cdn.cafecito.app/imgs/buttons/button_6.png 1x, https://cdn.cafecito.app/imgs/buttons/button_6_2x.png 2x, https://cdn.cafecito.app/imgs/buttons/button_6_3.75x.png 3.75x' src='https://cdn.cafecito.app/imgs/buttons/button_6.png' alt={text['alt-cafecito']} />
						</Dropdown.Item>
						<Dropdown.Item className="a-donation" href='https://ko-fi.com/N4N3O2G9M' rel="noopener noreferrer" target='_blank'>
							<img className="img-donation" src='https://storage.ko-fi.com/cdn/kofi1.png?v=3' border='0' alt={text['alt-kofi']} />
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
				<Tooltip id="tt-donations" />
            </div>
        </Stack>
    </nav>
  );
}

export default BarLeft;