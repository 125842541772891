import './styles.css';

const Player = () => {
    /*const { player, changePlayer } = useContext(PlayerContext);
    const handleSongChange = (instruction) => {
        changePlayer(instruction);
    };*/


    return (
        <>
        </>
    );
};

export default Player;