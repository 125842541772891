import './styles.css';

const Component = () => {
    return (
        <>
        
        </>
    );
};

const data = {
    title: {
        ES: "Atlas",
        EN: "Atlas",
    },
    href: 'atlas',
    body: <Component/>,
}

export default data;